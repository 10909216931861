<template>
  <div v-if="(getRight('subscription') && permittedSubscriptions.length) || getRight('superadmin')" class="card">
    <div class="card-header card-header-divider header_green">
      Измеритель настроения
    </div>
    <div class="card-body">
      <div class="info_img_wrapper">
        <img class="info_img" src="@/assets/images/tracker-instruction.svg" alt="Инструкция к Измерителю Настроения"/>
        <img class="info_img" src="@/assets/images/tracker-instruction-2.svg" alt="Инструкция к Измерителю Настроения"/>
      </div>
      <div class="analytics_button_wrapper">
        <div>
          <p>Посмотреть аналитику эффективности выполнения задач по кнопке &laquo;Аналитика&raquo;.</p>
        </div>
        <div>
          <button class="btn btn-accent">Аналитика</button>
        </div>
      </div>
      <mood-tracker-form/>
    </div>
  </div>
  <div v-else class="subscription-required">
    Чтобы использовать этот инструмент, необходимо оформить подписку. Пожалуйста, сделайте это для получения доступа.
  </div>
</template>

<script>
import MoodTrackerForm from "@/views/MoodTracker/MoodTrackerForm.vue";
import useCheckRights from "@/mixins/useCheckRights";
import {computed} from "@vue/reactivity";
import {watch} from "@vue/runtime-core";
import {useStore} from "vuex";

export default {
  name: "MoodTrackerPage",
  components: {MoodTrackerForm},
  setup() {
    const store = useStore();
    const {getRight} = useCheckRights();
    const user = computed(() => store.state.profile.profile);

    const permittedSubscriptions = computed(() => store.state.subscription.permittedSubscriptions)

    watch(() => user.value, () => {
      if (getRight('subscription') && user.value?.company?.id) {
        store.dispatch('subscription/getPermittedSubscriptions', user.value.company?.id);
      }
    });

    return {
      getRight,
      permittedSubscriptions
    }
  }
}
</script>

<style scoped lang="scss">
.header_green {
  font-size: 24px;
  font-weight: bold;
  color: #51bb50;
}

.info_img_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.info_img {
  width: 100%;
  height: 450px;
}

.subscription-required {
  padding-bottom: 30px;
}

.analytics_button_wrapper {
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
}
</style>