<template>
  <div class="tracker_wrapper" v-if="!isLoading">
    <div class="question_wrapper color_wrapper">
      <p class="question">В каком эмоциональном состоянии вы сейчас находитесь?</p>
      <ul class="color_list">
        <li v-for="(value, key) in colorsMap" :key="key">
          <div class="mood_color_block" :class="[{'active': value === activeColor}, key]"
               @click="() => !isCompleted && pickColor(value)"></div>
        </li>
      </ul>
    </div>
    <div class="question_wrapper tasks_wrapper" v-if="moodForm.color_id">
      <p class="question">Какую задачу вам сейчас предстоит выполнить?</p>
      <custom-select-2
          :disabled="isCompleted"
          :labelWidth="28"
          :options="taskOptions"
          v-model="moodForm.task_id"
          name="trigger_list"
      />
    </div>
    <div class="question_wrapper helpfulness_wrapper" v-if="moodForm.task_id">
      <p class="question">
        Помогает ли вам ваше текущее эмоциональное состояние в выполнении данной задачи?
      </p>
      <div class="answers">
        <custom-radio
            :disabled="isCompleted"
            v-model="moodForm.is_mood_helpful"
            id="radio-helpful"
            label="Да"
            name="is_helpful"
            :value="1"
            @update:modelValue="!isCompleted && getRecommendation()"
        />
        <custom-radio
            :disabled="isCompleted"
            v-model="moodForm.is_mood_helpful"
            id="radio-not-helpful"
            label="Нет"
            name="is_helpful"
            :value="0"
            @update:modelValue="!isCompleted && getRecommendation()"
        />
      </div>
    </div>
    <div class="recommendation_wrapper" v-if="isCompleted">
      <p v-if="parseInt(moodForm.is_mood_helpful) && appropriateMood">
        Замечательно! Ваше эмоциональное состояние из
        <span class="color_text" :class="`text_${getColorByName(activeColor)}`">{{colorTranslateMap[activeColor] + ' квадрата' }}</span> Измерителя Настроения очень поможет вам в эффективном выполнении поставленной задачи.
      </p>
      <p v-else-if="!parseInt(moodForm.is_mood_helpful) && appropriateMood">
        Вы отметили, что ваше эмоциональное состояние не соотносится с поставленной задачей. Тем не менее именно это состояние может способствовать успешному решению похожих задач. Попробуйте взглянуть на ситуацию под другим углом.
      </p>
      <p v-else-if="parseInt(moodForm.is_mood_helpful) && !appropriateMood">
        Вы отметили, что ваше эмоциональное состояние соотносится с задачей. Однако данное эмоциональное состояние не всегда оказывается оптимальным для решения аналогичных задач. Попробуйте посмотреть на ситуацию с другой стороны.
      </p>
      <div v-else-if="!parseInt(moodForm.is_mood_helpful) && !appropriateMood" class="square_recommendation_wrapper">
        <p>
          К сожалению, выполнение поставленной задачи не будет произведено наиболее эффективно в эмоциональном состоянии
          из <span class="color_text" :class="`text_${getColorByName(activeColor)}`">{{ colorTranslateMap[activeColor] + ' квадрата'}}</span> Измерителя Настроения.
          Задачи такого типа эффективнее всего выполнять в эмоциональном состоянии из
          <span class="color_text" :class="`text_${getColorByName(recommendedColor)}`">{{ colorTranslateMap[recommendedColor] + ' квадрата'}}</span> Измерителя Настроения.
        </p>
      </div>
    </div>
    <div v-if="isCompleted" class="repeat_button_wrapper">
      <button class="btn btn-accent" @click="reset">
        Измерить настроение для следующей задачи
      </button>
      <button v-if="!isSaved" class="btn btn-accent" @click="saveRecord" :disabled="isButtonDisabled">
        Сохранить
      </button>
    </div>
  </div>
  <div v-else class="loader">
    <mini-preloader/>
  </div>
</template>

<script>
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {useStore} from "vuex";
import {onBeforeMount, ref} from "@vue/runtime-core";
import {computed, reactive} from "@vue/reactivity";
import MiniPreloader from "@/components/Technical/MiniPreloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomRadio from "@/components/Forms/Fields/CustomRadio.vue";

export default {
  name: "MoodTrackerForm",
  components: {CustomRadio, CustomInput, MiniPreloader, CustomSelect2},
  setup() {
    const store = useStore();

    const colorList = computed(() => store.state.tracker.colorList);
    const taskList = computed(() => store.state.tracker.taskList);

    const isLoading = ref(false);
    const isSaved = ref(false);
    const isCompleted = ref(false);
    const activeColor = ref('');
    const recommendedColor = ref('');
    const taskOptions = ref([]);
    const appropriateMood = ref();
    const isButtonDisabled = ref(false);

    const moodForm = reactive({
      color_id: null,
      task_id: null,
      is_mood_helpful: null,
    });

    const colorsMap = {
      red: 'Красный',
      yellow: 'Жёлтый',
      blue: 'Синий',
      green: 'Зелёный',
    };

    const colorTranslateMap = {
      'Жёлтый': 'Жёлтого',
      'Красный': 'Красного',
      'Зелёный': 'Зелёного',
      'Синий': 'Синего',
    };

    const pickColor = (value) => {
      const color = colorList.value.find((el) => el.name === value);
      moodForm.color_id = color.id;
      activeColor.value = value;
    }

    const getRecommendation = () => {
      let taskColor = taskList.value.find((el) => el.id === parseInt(moodForm.task_id));
      let bestForTaskColor = colorList.value.find((el) => el.id === parseInt(taskColor.color_id));
      appropriateMood.value = moodForm.color_id === taskColor.color_id;
      recommendedColor.value = bestForTaskColor.name;
      isCompleted.value = true;
    }

    const reset = () => {
      moodForm.task_id = null;
      moodForm.is_mood_helpful = null;
      moodForm.color_id = null;
      activeColor.value = null;
      isCompleted.value = false;
      isSaved.value = false;
    }

    const getColorByName = (name) => {
      return Object.keys(colorsMap).find(key => colorsMap[key] === name);
    }

    const saveRecord = () => {
      isButtonDisabled.value = true;
      store.dispatch('tracker/createRecord', moodForm).then(() => {
        isSaved.value = true;
        isButtonDisabled.value = false;
      }).catch(() => {
        isButtonDisabled.value = false;
      });
    }

    onBeforeMount(() => {
      isLoading.value = true;

      store.dispatch('tracker/getTasks').then(() => {
        taskOptions.value = taskList.value.map((el) => {
          return {id: el.id, text: el.task};
        });
      });
      store.dispatch('tracker/getColors').then(() => {
        isLoading.value = false;
      });
    });

    return {
      moodForm,
      taskOptions,
      colorList,
      colorsMap,
      colorTranslateMap,
      activeColor,
      pickColor,
      isLoading,
      isCompleted,
      getRecommendation,
      recommendedColor,
      getColorByName,
      reset,
      saveRecord,
      isSaved,
      appropriateMood,
      isButtonDisabled
    }
  }
}
</script>

<style scoped lang="scss">

.tracker_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 50px 20px;
}

.mood_color_block {
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.mood_color_block:hover {
  opacity: 0.9;
}

.color_list {
  display: flex;
  width: 100%;
  column-gap: 25px;
}

.question_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}

.question {
  font-size: 18px;
}

.answers {
  display: flex;
  column-gap: 25px;
}

.tasks_wrapper .form-group {
  justify-content: flex-start;
}

.loader {
  padding: 120px 0;
  text-align: center;
}

.yellow {
  background-color: #ffec8a;
}

.green {
  background-color: #b0dc9c;
}

.blue {
  background-color: #8298cc;
}

.red {
  background-color: #f29175;
}

.color_text {
  font-weight: bold;
}

.text_yellow {
  color: #ffec8a;
}

.text_green {
  color: #b0dc9c;
}

.text_blue {
  color: #8298cc;
}

.text_red {
  color: #f29175;
}

.active {
  border: 1px solid #00e287;
}

.repeat_button_wrapper {
  display: flex;
  column-gap: 10px;
  margin-left: auto;
}

.recommendation_wrapper {
  margin-top: 15px;
  font-size: 18px;
  text-align: justify;
}

.square_recommendation_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media (max-width: 400px) {
  .color_list {
    flex-direction: column;
    row-gap: 10px;
  }

  .mood_color_block {
    width: 100%;
  }
}
</style>